<template>
  <div class="px-8 py-10 container" style="max-width: 1150px;margin:0 auto;">
    <div class="flex flex-wrap">
      <div class="w-full">
        <TitleComponent
          class="py-8"
          title="In-Process Thermal Test Standardization"
          size="large"
        />

        <p class="font-bold">
          Thermal testing determines melting temperatures for processing
          evaluations and set points, decomposition onset temperatures for
          in-process limits, compatibility of chemicals for use in formulations
          or use in the same processing area as the energetic, and screening
          tests for energetic output as measured by the exothermic heat released
          upon decomposition (energy/mass). Equipment used for thermal testing
          includes a DSC, SBAT, TGA, or ARC.
        </p>

        <p class="font-bold mt-4">
          Thermal testing is used to determine the conditions at which a
          substance begins to breakdown and release energy. Both small and large
          collections of energetic materials can breakdown violently when
          exposed to a thermal impetus. The
          <router-link class="link" to="/ip-thermal-test"
            >Simulated Bulk Auto-Ignition Test</router-link
          >
          (SBAT) is used to test larger sample sizes to better estimate the
          ignition temperature of in-process bulk samples. The Differential
          Scanning Calorimeter (DSC) is better suited for in-process conditions
          where the sample size is small. Standardization efforts for the two
          tests are detailed below including standardized procedures and Round
          Robin test results.
        </p>

        <div
          class="flex flex-wrap mt-8 mb-4 px-6 py-10"
          style="background-color:#f7f5f4;"
        >
          <div class="w-full md:w-1/2 pr-3">
            <h2
              class="mb-6"
              style="font-size:22.5px;line-height: 30.938px;font-weight:bold;"
            >
              VERIFICATION OF STANDARDIZATION
            </h2>

            <p
              style="color:rgb(127, 137, 145);line-height:32px;font-size:15px;"
            >
              Testing can be completed by any lab to verify standardization
              efforts (procedures, calibration, material, etc.). The steps below
              further detail completion of the verification or Round Robin
              testing.
            </p>
            <ol class="p-light list-decimal pl-10 mt-4">
              <li>Download and implement the above detailed procedures,</li>
              <li>
                Obtain the PETN and/or RDX sample, in this case, any PETN or RDX
                sample can be used,
              </li>
              <li>
                Complete testing according to the detailed instructions in the
                respective standard Round Robin/VoS procedure (link under
                'Standardized Procedures', if not shown, request access
                <router-link
                  style="color:#ef9a3d;"
                  to="/standardization-and-verification/round-robin-participation"
                  >here</router-link
                >), and
              </li>
              <li>
                Share your results with the ET Users Group community. You can
                <router-link class="link" to="/contact-us/"
                  >contact us here</router-link
                >.
              </li>
            </ol>
            <div class="mb-6 mt-6">
              <h2
                v-if="
                  $store.getters['user/userClasses']['role-thermal-test-dsc'] ||
                    $store.getters['user/userClasses']['role-thermal-test-sbat']
                "
                class="mb-6"
                style="font-size:22.5px;line-height: 30.938px;font-weight:bold;"
              >
                STANDARDIZED PROCEDURES
              </h2>
              <div class="mb-6 p-light thermal-procedure-list">
                <ol class="pl-10 list-decimal">
                  <li
                    v-if="
                      $store.getters['user/userClasses'][
                        'role-thermal-test-dsc'
                      ]
                    "
                  >
                    <a
                      class="link"
                      href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/DSC%20Round%20Robin%20-%20ETUG-254%2C%20Rev%202.pdf?alt=media&token=3d106989-5929-463f-88e8-41a3b2b43fd3"
                      >DSC Round-Robin Procedure</a
                    >
                  </li>
                  <li
                    v-if="
                      $store.getters['user/userClasses'][
                        'role-thermal-test-sbat'
                      ]
                    "
                  >
                    <a
                      class="link"
                      href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/ETUG-188%20Rev%202%20Round%20Robin%20SBAT.pdf?alt=media&token=a6bdd5c1-8cb0-477e-ae06-86ed649cdd13"
                      target="_blank"
                      rel="noreferrer noopener"
                      aria-label=" (opens in a new tab)"
                      >SBAT Round-Robin Procedure</a
                    >
                  </li>
                </ol>
              </div>
            </div>
            <h2
              class="mb-6 mt-6"
              style="font-size:22.5px;line-height: 30.938px;font-weight:bold;"
            >
              DSC ET Users Group Results (To Date)
            </h2>

            <p class="p-light mb-4">
              For repeatability purposes, the melting temperatures of tin. The
              average melting temperature of tin over three trials should be
              between 230.9˚C and 232.0˚C.
            </p>

            <p class="p-light mb-4">
              The plots below depict the Round Robin results. The average
              distribution is indicated by a black horizontal line and the
              standard deviation by the highlighted green area. A statistical
              cutoff for the data summary was established using a 95% confidence
              level, which corresponds to a 5% false positive rate. This false
              positive rate equates to a cutoff of 2.5 as shown in the
              Comparison to Average Distribution figure. Decomposition Onset
              Temperatures for PETN (average=176.5 C, stdev=2.2)
            </p>

            <a
              :href="
                require('../../assets/PETN-Decomposition-Onset-Temperatures-min.png')
              "
              target="_blank"
            >
              <img
                class="m-auto"
                src="@/assets/PETN-Decomposition-Onset-Temperatures-min.png"
              />
            </a>

            <p
              class="mt-6 mb-4"
              style="color:rgb(127, 137, 145);line-height:32px;font-size:15px;"
            >
              Results range from 160°C to 184°C. Given the differences in
              manufacturers of the PETN and the particle sizes of the PETN, the
              above results are satisfactory. The cutoff may need to be adjusted
              as use of a common sample requires shipment of the explosive to
              various locations around the world, which can be difficult.
              Melting Onset Temperatures for PETN (average=140.5 C, stdev=0.88).
            </p>

            <a
              :href="
                require('../../assets/PETN-Melting-Onset-Temperatures-min.png')
              "
              target="_blank"
            >
              <img
                class="m-auto"
                src="@/assets/PETN-Melting-Onset-Temperatures-min.png"
              />
            </a>
            <p
              class="p-light mt-6"
              v-if="
                $store.getters['user/userClasses']['role-thermal-test-dsc'] ||
                  $store.getters['user/userClasses']['role-thermal-test-sbat']
              "
            >
              Further details of the results are documented in the summary
              presentation
              <a
                class="link"
                target="_blank"
                href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2018%2F015-ETUGDSCSummaryofResults.pdf?alt=media&token=2f36d8c2-d7ce-45a1-a33b-2df8f2af4373"
                >here</a
              >.
            </p>
          </div>
          <div class="w-full md:w-1/2 pl-3">
            <h2
              class="mb-6"
              style="font-size:22.5px;line-height: 30.938px;font-weight:bold;"
            >
              SBAT ET Users Group Results (To Date)
            </h2>
            <p class="p-light mb-4">
              The tables for PETN and RDX below show the consitent onset
              temperatures obtained between laboratories. The onset temperature
              was determined as described in the procedure.
            </p>

            <a
              :href="require('../../assets/sbatresultstable.png')"
              target="_blank"
            >
              <img class="m-auto" src="@/assets/sbatresultstable.png" />
            </a>
            <p
              class="p-light mt-6"
              v-if="
                $store.getters['user/userClasses']['role-thermal-test-dsc'] ||
                  $store.getters['user/userClasses']['role-thermal-test-sbat']
              "
            >
              Further details of the results are documented in the summary
              presentation
              <a
                class="link"
                target="_blank"
                href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/ETUG%20SBAT%20Summary%20of%20Results%20-%20Onset.pdf?alt=media&token=692a3a8d-62c8-4e63-80d8-b8d8263a4038"
                >here</a
              >.
            </p>

            <h2
              class="my-6"
              style="font-size:22.5px;line-height: 30.938px;font-weight:bold;"
            >
              Statistics
            </h2>
            <p class="p-light">
              A significant part of any VoS or Round Robin effort is determining
              the criteria that defines agreement or disagreement between test
              facilities. It’s highly unlikely that the exact same parameters
              defining the reaction transition for a given material and test
              machine will be obtained by all the sites even if they were done
              exactly the same way. As such, it is likely that the results from
              each laboratory or within the same laboratory will differ. The
              criteria to say that the results are different or not should be
              statistically based. The ET Users Group has adopted the SRC Method
              or the
              <a
                class="link"
                href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/StatisticalRelativeComparisonMethod.pdf?alt=media&token=fd5b8bc1-c5de-4c4c-b12b-77bbf892e6ac"
                >Statistical Relative Comparison (SRC) Method</a
              >
              to determine if differences in the sensitivity test outcomes are
              indeed significantly different from the ET Users Group community
              given above.
            </p>
          </div>
          <div class="w-full">
            <h2
              class="mb-6 mt-6"
              style="font-size:22.5px;line-height: 30.938px;font-weight:bold;"
            >
              Feedback
            </h2>
            <p class="p-light">
              If you have any feedback on the information found here or have
              completed testing please share your feedback and/or results with
              us
              <router-link class="link" to="/contact-us"
                >(Contact Us)</router-link
              >.
            </p>
          </div>
        </div>
        <div class="w-3/4 m-auto text-left mt-12 text-md">
          <p class="leading-relaxed" style="color:#7e8890;">
            Terms of Use
            <br />
            All material on this website is copyrighted by the ET Users Group™.
            By accessing the content in the Test Methods Matrix™ you agree to
            the following:
          </p>
        </div>
        <div
          style="color:#7e8890;"
          class="w-3/4 pl-16 md:pl-32 text-left ml-10 mt-4 text-md"
        >
          <ul class="list-disc">
            <li class="mt-2">
              You will not make copies of this material or otherwise make this
              content available offline for others (such as but not limited to
              creating training materials) without written consent from the ET
              Users Group;
            </li>
            <li class="mt-2">
              You will direct inquiries regarding access to this content to the
              ET Users Group;
            </li>
            <li class="mt-2">
              You will not hold liable the ET Users Group or any associated
              entity for damages caused by the use or misuse of this content.
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TitleComponent from "@/components/TitleComponent";

export default {
  components: {
    TitleComponent
  },
  data: function() {
    return {
      showContent: false
    };
  }
};
</script>

<style lang="scss"></style>
